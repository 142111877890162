<template>
    <v-card flat justify-center :color="color" dark :height="height">
        <v-card-title class="title pt-0 pb-1 black--text">
            <v-btn v-if="choseable" block color="info" @click="chose">
                <v-icon left>touch_app</v-icon> {{ title }}<v-icon left>attach_money</v-icon>{{ rate }}
            </v-btn>
        </v-card-title>
        <v-card-text class="pt-0 black--text">
            {{ title }}
            <ul v-for="(note, i) in notes" :key="i">
                <li>{{ note }}</li>
            </ul>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'plan-card',
    props: {
        title: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        rate: {
            type: String,
            default: ''
        },
        choseable: {
            type: Boolean,
            default: false
        },
        notes: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        chose() {
            this.$emit('chose', this.title)
        }
    }
}
</script>